import { stringify } from 'qs';
import request from './request';

export const post = async (url: string, data: any) => {
  const requestURL = url;
  const requestOpts = {
    method: 'POST',
    body: JSON.stringify(data),
  };
  return request(requestURL, requestOpts);
};

export const update = async (url: string, data: any) => {
  const requestURL = url;
  const requestOpts = {
    method: 'PATCH',
    body: JSON.stringify(data),
  };
  return request(requestURL, requestOpts);
};

export const get = async (url: string, params: any) => {
  const requestURL = url;
  const { page, sizePerPage, filter, sort } = params;

  const query = {
    ...filter,
    p: page,
    l: sizePerPage,
  };

  if (sort) {
    Object.assign(query, {
      sort: `${sort.s}:${sort.o}`,
    });
  }

  const urlQuery = stringify(query, { addQueryPrefix: true, encode: false });

  const requestOpts = {
    method: 'GET',
  };

  return request(requestURL + urlQuery, requestOpts);
};

export const getSingleById = async (url: string, id?: number, params?: any) => {
  const requestOpts = {
    method: 'GET',
  };
  let urlRequest = url;

  if (id) {
    urlRequest += `/${id}`;
  }

  if (params) {
    urlRequest += stringify(params, { addQueryPrefix: true, encode: false });
  }

  return request(urlRequest, requestOpts);
};
