/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from 'react';
import { Route, Switch, withRouter, useLocation } from 'react-router-dom';
import { NoAuthTemplate } from 'themes';
import { Async } from 'components/layouts';
import { useDispatch } from 'react-redux';
import { userCheck } from 'redux/auth/actions';
import ReduxToastr from 'react-redux-toastr';
import { AppContext } from 'helpers';
import moment from 'moment';
import NepaliDate from 'nepali-date';
import { MONTH_LIST, NEPAL_MONTH_LIST, NEPAL_TZ } from 'constant';
import router from './routes';

const App = () => {
  const dispatch = useDispatch();
  const availabelRoutes = router;
  const [month, setMonth] = useState<string>('');
  const [year, setYear] = useState<string>('');
  const [timezone, setTimezone] = useState<string>('');
  const [monthList, setMonthList] = useState<any>([]);
  const settings = JSON.parse(localStorage.getItem('tvs.settings') || '{}');

  useEffect(() => {
    if (settings.timezone) {
      setTimezone(settings.timezone);
    }
  }, [settings]);

  useEffect(() => {
    let currentTime: any = {};
    if (timezone === NEPAL_TZ) {
      const now = new NepaliDate();
      currentTime = {
        currentMonth: now.format('MM'),
        currentYear: now.format('YYYY'),
      };
      setMonthList(NEPAL_MONTH_LIST);
    } else {
      currentTime = {
        currentMonth: moment().format('MM'),
        currentYear: moment().format('Y'),
      };
      setMonthList(MONTH_LIST);
    }
    setMonth((currentTime.currentMonth - 1).toString());
    setYear(currentTime.currentYear);
  }, [timezone]);

  useEffect(() => {
    let currentTime: any = {};
    if (timezone === NEPAL_TZ) {
      const now = new NepaliDate();
      currentTime = {
        currentMonth: now.format('MM'),
        currentYear: now.format('YYYY'),
      };
      setMonthList(NEPAL_MONTH_LIST);
    } else {
      currentTime = {
        currentMonth: moment().format('MM'),
        currentYear: moment().format('Y'),
      };
      setMonthList(MONTH_LIST);
    }
    setMonth((currentTime.currentMonth - 1).toString());
    setYear(currentTime.currentYear);
  }, [timezone]);

  const routeRenderer = (Layout: FC, pageComponent: string, noAuth = false) => {
    if (!noAuth) {
      dispatch(userCheck({}, '/login'));
    }
    return (
      <Layout>
        <Async page={pageComponent} />
      </Layout>
    );
  };

  const routeListRedenrer = () => {
    return availabelRoutes.map(({ noAuth, path, pageComponent, layout, isExact = false }, index) => {
      const template = layout || NoAuthTemplate;
      return (
        <Route
          key={`${pageComponent}-root`}
          path={path}
          exact={isExact}
          render={() => routeRenderer(template, pageComponent, noAuth)}
        />
      );
    });
  };

  return (
    <AppContext.Provider
      value={{
        month,
        year,
        setMonth,
        setYear,
        timezone,
        monthList,
      }}
    >
      <div className="App">
        <Switch>{routeListRedenrer()}</Switch>
        <ReduxToastr
          timeOut={60000000}
          newestOnTop={false}
          preventDuplicates
          position="top-left"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
      </div>
    </AppContext.Provider>
  );
};

export default withRouter(App);
