/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
import TimeIdle from 'components/elements/IdleTime';
import { Header, Footer } from 'components/layouts';
import { IDLE_TIMEOUT } from 'constant';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { userLogout } from 'redux/auth/actions';
import { clearActionStatus } from 'redux/crud/action';
import styles from 'styled-components';

type AuthTemplateProps = {
  // children?: FC;
};

const AuthTemplate: FC<AuthTemplateProps> = ({ children }) => {
  const [isTimeout, setIsTimeout] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const timer = new TimeIdle({
      timeout: IDLE_TIMEOUT,
      onTimeout: () => {
        setIsTimeout(true);
      },
      onExpire: () => {
        dispatch(userLogout());
        dispatch(clearActionStatus('userTc'));
      },
    });

    return () => {
      timer.cleanUp();
    };
  }, []);

  const handleTimeout = () => {
    dispatch(userLogout());
    setIsTimeout(false);
  };
  return (
    <Main>
      <Header />
      <div
        style={{
          marginTop: '44px',
        }}
      >
        {children}
      </div>
      <Modal isOpen={isTimeout} centered>
        <ModalBody>
          <div>Your session is expired. Please login again</div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => handleTimeout()} color="primary border-0">
            Ok
          </Button>
        </ModalFooter>
      </Modal>
      <Footer />
    </Main>
  );
};
const Main = styles.div`
  background-color: #f5f9fb;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh
`;

export default AuthTemplate;
