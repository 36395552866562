import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { Container, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Nav, NavItem } from 'reactstrap';
import { Localization } from 'constant/config';
import { userLoad } from 'redux/auth/actions';
import authProvider from 'utils/authProvider';
import styles from 'styled-components';
import { Link } from 'react-router-dom';
import { clearActionStatus } from 'redux/crud/action';

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const { auth } = useSelector((state: any) => state);
  const history = useHistory();

  const handleLogout = () => {
    authProvider.logout();
    history.push('/login');
    dispatch(clearActionStatus('userTc'));
  };

  useEffect(() => {
    if (auth?.token) {
      dispatch(userLoad());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!auth.token) {
      history.push('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const route = useRouteMatch();

  return (
    <Div>
      <Container>
        <div className="d-flex justify-content-between">
          <Link to="/">
            <img
              src={`${process.env.PUBLIC_URL}/images/${Localization.logoFilename}`}
              alt={Localization.clientName}
              height="44"
            />
          </Link>
          {(auth?.user?.role === 'C' || auth?.user?.role === 'M') && (
            <Nav>
              <NavItem className={route?.path === '/' ? 'active' : ''}>
                <LinkStyle to="/">Dashboard</LinkStyle>
              </NavItem>
              {auth?.user?.role === 'C' && (
                <NavItem className={route?.path.indexOf('branches') > -1 ? 'active' : ''}>
                  <LinkStyle to="/branches">Branches</LinkStyle>
                </NavItem>
              )}
              <NavItem className={route?.path.indexOf('specialist') > -1 ? 'active' : ''}>
                <LinkStyle to="/specialist">Collection Specialist</LinkStyle>
              </NavItem>
              <NavItem className={route?.path.indexOf('cashier') > -1 ? 'active' : ''}>
                <LinkStyle to="/cashiers">Cashier</LinkStyle>
              </NavItem>
              <NavItem className={route?.path.indexOf('account') > -1 ? 'active' : ''}>
                <LinkStyle to="/accounts">Accounts</LinkStyle>
              </NavItem>
            </Nav>
          )}

          {auth?.user?.role === 'A' && (
            <Nav>
              <NavItem className={route?.path === '/' ? 'active' : ''}>
                <LinkStyle to="/">Collection Accounts</LinkStyle>
              </NavItem>
              {Localization.activateTransactionListing && (
                <NavItem className={route?.path.indexOf('transactions') > -1 ? 'active' : ''}>
                  <LinkStyle to="/transactions">Transactions</LinkStyle>
                </NavItem>
              )}
            </Nav>
          )}
          <div>
            <DropdownStyle isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggleType>
                <div className="d-flex align-items-center">
                  <div className="header-avatar" />
                  {auth?.user?.first_name} {auth?.user?.last_name}
                  <div className={!dropdownOpen ? 'pb-1' : ''}>
                    {!dropdownOpen ? (
                      <div className="ml-2 header-arrow-down" />
                    ) : (
                      <div className="ml-2 header-arrow-up" />
                    )}
                  </div>
                </div>
              </DropdownToggleType>
              <DropdownMenuStyle container="body">
                <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
              </DropdownMenuStyle>
            </DropdownStyle>
          </div>
        </div>
      </Container>
    </Div>
  );
};
const LinkStyle = styles(Link)`
  margin-right: 30px;
  color: #fff;
`;

const DropdownMenuStyle = styles(DropdownMenu)`
  width: 237px;
`;

const Div = styles.div`
  background: ${Localization.navbarBackgroundColor};
  padding: 5px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
`;
const DropdownStyle = styles(Dropdown)`
  font-weight: 400;
  &:hover, &:focus, &:visited {
    background: transparent;
    border: none;
    box-shadow: none;
    outline: 0
  }
`;
const DropdownToggleType = styles(DropdownToggle)`
  background: transparent;
  border: none;
  border-radius: 0;
  font-family: 'Roboto', sans-serif;
  &:hover, &:focus, &:visited, &:active {
    border: none !important;
    background: #AA0000 !important;
    border: none;
    box-shadow: none  !important;
    outline: 0;
  }
`;

export default Header;
