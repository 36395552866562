/* eslint-disable @typescript-eslint/naming-convention */
import httpClient from './request';

const apiUrl = process.env.REACT_APP_API_URL! + process.env.REACT_APP_API_PREFIX;
const tokenKey = `${process.env.REACT_APP_BASENAME}.token`;
const tokenDataKey = `${process.env.REACT_APP_BASENAME}.token_data`;
const settingsKey = `${process.env.REACT_APP_BASENAME}.settings`;
interface LoginData {
  token: string;
  expire: string;
  refresh_token: string;
  s;
}

const login = (payload: any): Promise<LoginData> => {
  const reqOpts = {
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return httpClient(`${apiUrl}/cms/login`, reqOpts).then(
    (resp: any): Promise<any> => {
      try {
        setToken(resp);
        return Promise.resolve(resp);
      } catch (error: any) {
        return Promise.reject(error.message);
      }
    },
  );
};

const getToken = () => {
  if (localStorage.getItem(tokenKey)) {
    return JSON.parse(localStorage.getItem(tokenKey) || '{}');
  }
  return false;
};

const getTokenData = () => {
  if (localStorage.getItem(tokenDataKey)) {
    return JSON.parse(localStorage.getItem(tokenDataKey) || '{}');
  }
  return false;
};
const getSettingsData = () => {
  if (localStorage.getItem(settingsKey)) {
    return JSON.parse(localStorage.getItem(settingsKey) || '{}');
  }
  return false;
};
const setToken = (resp: any) => {
  if (resp && resp.user && resp.settings) {
    const { acc_token } = resp.user;
    localStorage.setItem(tokenDataKey, JSON.stringify(resp.user));
    localStorage.setItem(tokenKey, JSON.stringify(acc_token));
    localStorage.setItem(settingsKey, JSON.stringify(resp.settings));
    return acc_token;
  }
  return false;
};

// called when the user navigates to a new location
const checkAuth = () => {
  return getToken() ? Promise.resolve() : Promise.reject();
};

// called when the user attempts to logout
const logout = (): Promise<any> => {
  const reqOpts = {
    method: 'POST',
  };

  if (!getToken()) {
    return Promise.resolve(removeToken());
  }

  return httpClient(`${apiUrl}/cms/logout`, reqOpts).then(
    (resp: any): Promise<any> => {
      try {
        return Promise.resolve(removeToken());
      } catch (error: any) {
        return Promise.reject(error.message);
      }
    },
  );
};

const removeToken = () => {
  localStorage.removeItem(tokenKey);
  localStorage.removeItem(tokenDataKey);
};

// get the current user details
const getUserDetails = () => {
  const reqOpts = {
    method: 'GET',
  };

  return httpClient(`${apiUrl}/users/me`, reqOpts).then(
    (resp: any): Promise<any> => {
      try {
        return Promise.resolve(resp);
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
  );
};

const authProvider = { login, logout, checkAuth, getToken, getTokenData, removeToken, getUserDetails, getSettingsData };
export default authProvider;
